<template>
    <div class="scan-page">

        <!-- Barcode picker -->
        <scanner
            @barcodeHasBeenScanned="barcodeWasScanned($event)"
        ></scanner>


        <!-- Empty state -->
        <empty-state
                v-if="!consignmentIdBeingReturned"
                icon="scanner"
                title="Scan a shipment">
        </empty-state>

        <!-- Outbound shipment scanned --->
        <template v-if="consignmentIdBeingReturned">
            <!-- Details -->
            <div class="scan-details">
                <div class="container">
                    <h4 class="d-flex align-items-center mb-2">
                        Return shipment {{ consignmentIdBeingReturned }}
<!--                        <button class="btn btn-success btn-sm ml-auto" @click="$bvModal.show('complete-return-shipment-modal')">Complete return</button>-->
                    </h4>
                    <h5 class="text-subtitle mb-0">{{ consignmentDetails.length }} assets added</h5>
                </div>
            </div>

            <!-- Item list -->
            <div class="container scan-content">
                <div class="card-grid vertical" v-if="consignmentDetails.length > 0">
                    <div
                            v-for="detail in consignmentDetails"
                            :key="detail.id"
                            class="card-link-container">
                        <div class="card card-body card-body-sm">
                            Asset: {{ detail.reference }}
                        </div>
                    </div>
                </div>
                <div class="card" v-if="consignmentDetails.length < 1">
                    <empty-state
                            icon="scanner"
                            title=""
                            subtitle="Scan the items you're returning in this shipment">
                    </empty-state>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        data() {
            return {
                assets: null
            }
        },

        computed: {
            ...mapGetters([
                'appRegion',
                'asset',
                'consignment',
                'consignmentDetails',
                'consignmentIdBeingReturned'
            ])
        },

        mounted() {
            this.clearReturnConsignmentScanned();
        },

        methods: {
            ...mapActions([
                'addDetailToConsignment',
                'clearReturnConsignmentScanned',
                'displayToast',
                'loadAsset',
                'loadReturnConsignment',
                'loadReturnConsignmentDetails',
                'setAssetAddedToShipment',
                'setReturnConsignmentScanned'
            ]),

            /** Check the barcode type */
            barcodeWasScanned(barcodeScanned) {
                if (barcodeScanned.type === 'consignment') {
                    return this.consignmentScanned(barcodeScanned);
                }

                if (barcodeScanned.type === 'asset') {
                    if (!this.consignmentIdBeingReturned) {
                        return this.displayToast({text: 'Please scan a return shipment first', type: 'error'});
                    }

                    return this.assetScanned(barcodeScanned);
                }

                return this.displayToast({text: 'Unsupported barcode type', type: 'error'});
            },

            /** Asset scanned */
            assetScanned(barcodeScanned) {
                this.loadAsset({
                    assetId: barcodeScanned.id
                }).then(() => {

                    if (this.asset.added_to_shipment === true) {
                        return this.displayToast({text: 'This asset has already been added to a shipment', type: 'error'});
                    }

                    if (
                        (this.consignment.shipment_type_code === 'bulk-repair' || this.consignment.shipment_type_code === 'afc-bulk-repair')
                        && (this.asset.status_code !== 'repair-complete' && this.asset.status_code !== 'cannot-repair')
                    ) {
                        return this.displayToast({text: 'Asset repair must be either complete or beyond repair', type: 'error'});
                    }

                    if (
                        (this.consignment.shipment_type_code === 'bulk-repair-ber' || this.consignment.shipment_type_code === 'afc-bulk-repair-ber')
                        && this.asset.status_code !== 'cannot-repair'
                    ) {
                        return this.displayToast({text: 'Asset must be beyond repair', type: 'error'});
                    }

                    this.setAssetAddedToShipment({
                        assetId: barcodeScanned.id
                    });

                    this.addDetailToConsignment({
                        detail_type: barcodeScanned.type,
                        detail_id: barcodeScanned.id,
                        consignment: this.consignmentIdBeingReturned,
                        reference: barcodeScanned.data.reference
                    });

                    this.displayToast({text: 'Asset added to consignment', type: 'success'});

                });

            },

            /** Consignment scanned */
            consignmentScanned(barcodeScanned) {
                this.clearReturnConsignmentScanned();
                this.loadReturnConsignment({
                    consignment: barcodeScanned.id
                }).then(() => {
                    if (this.consignment.shipped === true) {
                        return this.displayToast({text: 'Shipment is already closed', type: 'error'});
                    }

                    this.setReturnConsignmentScanned(barcodeScanned.id)
                        .then(() => {
                            this.loadReturnConsignmentDetails({
                                barcodeScanned: barcodeScanned,
                                consignment: this.consignmentIdBeingReturned,
                            })
                        });

                }).catch((error) => {
                    return this.displayToast({text: error, type: 'error'});
                });
            }
        }
    }
</script>

<style scoped>

</style>